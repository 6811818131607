import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { basePathVrbt, baseVrbt } from "./app/constants";
import { AuthProvider } from "app/AuthContext";
import {
  encryptDataAES,
  getEncryptedPhoneNumber,
  getOS,
  getRemoteConfigValue,
} from "utils/helper";
import ScrollToTop from "utils/scrollToTop";

const RequirePhoneNumber = ({ children }) => {
  const location = useLocation();
  const phoneNumber = getEncryptedPhoneNumber();

  if (!phoneNumber) {
    return (
      <Navigate
        to={baseVrbt + `onboarding`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

const Middleware = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const keyMsisdn = queryParams.get("keyMsisdn");
  const keyCustParam = queryParams.get("keyCustParam");
  let keyOs = queryParams.get("keyOs");
  // const keyCustType = queryParams.get("keyCustType");
  // const lang = queryParams.get("lang");
  if (keyOs === "") {
    keyOs = "ios";
  }

  useEffect(() => {
    const validParams = ["artist", "song"];
    const hasValidQuery = Array.from(searchParams.keys()).some((key) =>
      validParams.includes(key)
    );

    const handleRedirection = async () => {
      if (keyOs !== "") {
        try {
          const secretKey = await getRemoteConfigValue(
            process.env.REACT_APP_KEY_REMOTE_CONFIG
          );
          const encryptedMsisdn = await encryptDataAES(keyMsisdn, secretKey);
          const storedEncryptedPhoneNumber = getEncryptedPhoneNumber();

          if (
            !storedEncryptedPhoneNumber ||
            storedEncryptedPhoneNumber !== encryptedMsisdn
          ) {
            navigate(
              baseVrbt +
                `onboarding?keyMsisdn=${keyMsisdn}&keyCustParam=${keyCustParam}&keyOs=${keyOs}`
            );
          } else {
            navigate(baseVrbt);
          }
        } catch (error) {
          console.error("Error during encryption or redirection logic:", error);
        }
      } else {
        if (hasValidQuery && location.pathname === baseVrbt && keyOs === "") {
          const redirectUrl = baseVrbt + `search?${searchParams.toString()}`;
          navigate(redirectUrl, {
            replace: true,
            state: { journey: true },
          });
        }
      }
    };

    handleRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, navigate, searchParams]);

  return children;
};

const MiddlewareRedirect = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const validParams = ["category", "artist", "song"];
    const hasValidQuery = Array.from(searchParams.keys()).some((key) =>
      validParams.includes(key)
    );

    if (
      hasValidQuery &&
      location.pathname === baseVrbt &&
      getOS() !== "android" &&
      getOS() !== "ios"
    ) {
      console.log("this fired?");
      const redirectUrl = baseVrbt + `search?${searchParams.toString()}`;
      navigate(redirectUrl, { replace: true, state: { journey: true } });
    }
  }, [searchParams, navigate, location]);

  return children;
};

const WapPage = React.lazy(() => import(basePathVrbt + "WapPage/"));
const WapOnboardingPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/OnboardingPage")
);
const WapDetailPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/DetailPage")
);
const WapSearchPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/SearchPage")
);
const WapGroupPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/GroupPage")
);
const WapCollectionPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/CollectionPage")
);
const WapCollectionDetailPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/CollectionDetailPage")
);
const WapTnCPage = React.lazy(() => import(basePathVrbt + "WapPage/TnCPage"));
const WapLoginPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/LoginPage")
);
const WapPermissionPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/PermissionPage")
);
///// VRBT CMS

const ProjectRoutes = () => {
  return (
    <React.Suspense
      fallback={
        <div
          className="bg-gradient-to-b from-[#052351] to-[#ffffff]"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f0f0f0", // Optional background color
          }}
        >
          <div className="loading-spinner" />
        </div>
      }
    >
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <MiddlewareRedirect>
            <Middleware>
              <Routes>
                {/* WAP */}
                <Route
                  path={baseVrbt}
                  element={
                    <RequirePhoneNumber>
                      <WapPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route path={baseVrbt + "login"} element={<WapLoginPage />} />
                <Route
                  path={baseVrbt + "onboarding"}
                  element={<WapOnboardingPage />}
                />
                <Route
                  path={baseVrbt + "permission"}
                  element={<WapPermissionPage />}
                />
                <Route
                  path={baseVrbt + "content/:content_id"}
                  element={
                    <RequirePhoneNumber>
                      <WapDetailPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "search"}
                  element={
                    <RequirePhoneNumber>
                      <WapSearchPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "group/:content_class_id"}
                  element={
                    <RequirePhoneNumber>
                      <WapGroupPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "collection"}
                  element={
                    <RequirePhoneNumber>
                      <WapCollectionPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "collection-details/"}
                  element={
                    <RequirePhoneNumber>
                      <WapCollectionDetailPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route path={baseVrbt + "tnc"} element={<WapTnCPage />} />
                <Route
                  path="*"
                  element={
                    <RequirePhoneNumber>
                      <WapPage />
                    </RequirePhoneNumber>
                  }
                />
              </Routes>
            </Middleware>
          </MiddlewareRedirect>
        </Router>
      </AuthProvider>
    </React.Suspense>
  );
};
export default ProjectRoutes;
