import { configureStore } from "@reduxjs/toolkit";
import { api } from "./api.js";
import authReducer from "./reducers/token.js";
import contentGroupReducer from "./reducers/contentGroup.js";
import { firebaseApi } from "./firebaseApi.js";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [firebaseApi.reducerPath]: firebaseApi.reducer,
    auth: authReducer,
    content_group: contentGroupReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
