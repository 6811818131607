import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, baseVrbt } from "./constants";
import {
  clearCookies,
  getEncryptedCustParam,
  getEncryptedData,
  getEncryptedID,
  getEncryptedPhoneNumber,
  getOS,
  getRefreshToken,
  getToken,
  isTokenExpired,
  saveRefreshToken,
  saveToken,
} from "utils/helper";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers) => {
    if (getOS() === "null") {
      const token = getToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }

    if (getOS() !== "null" || getOS() !== "") {
      const token = getRefreshToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
    return headers;
  },
});

const refreshToken = async (api, extraOptions) => {
  const maxRetries = 3;
  let attempt = 0;

  if (getOS() !== "") {
    const tryRefresh = async () => {
      const phoneNumber = getEncryptedData();
      const id = getEncryptedID();
      const cust_param = getEncryptedCustParam();
      if (!phoneNumber || !id || !cust_param) return false;

      try {
        // First API call to /get_token
        const formData1 = new FormData();
        formData1.append("data", phoneNumber);
        formData1.append("id", id);
        formData1.append("cust_param", cust_param);

        const initialResponse = await baseQuery(
          {
            url: "/get_token",
            method: "POST",
            body: formData1,
          },
          api,
          extraOptions
        );

        if (!initialResponse.data?.data || !initialResponse.data?.subject) {
          console.error("Invalid response from /get_token");
          return false;
        }
        saveToken(initialResponse.data.data);

        // Second API call to /refresh_token
        const formData2 = new FormData();
        formData2.append("data", phoneNumber);
        formData2.append("id", id);
        formData2.append("token", initialResponse.data.data);

        const refreshResponse = await baseQuery(
          {
            url: "/refresh_token",
            method: "POST",
            body: formData2,
          },
          api,
          extraOptions
        );

        if (refreshResponse.data?.token) {
          saveRefreshToken(refreshResponse.data.token);
          return true;
        }
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }

      return false;
    };

    while (attempt < maxRetries) {
      const success = await tryRefresh();
      if (success) return true;

      attempt++;
      console.warn(`Retrying token refresh... Attempt ${attempt}`);
    }
  }

  if (getOS() === "null") {
    try {
      const formData = new FormData();
      formData.append("data", getEncryptedPhoneNumber());

      const refreshResult = await baseQuery(
        {
          url: "/wap_token",
          method: "POST",
          body: formData,
        },
        api,
        extraOptions
      );

      if (refreshResult.data?.token) {
        saveToken(refreshResult.data.token);
        return true;
      }
    } catch (error) {
      console.error("Failed to refresh token:", error);
      window.location.href = baseVrbt + "onboarding";
    }
  }

  clearCookies();

  if (window.webkit && window.webkit.messageHandlers.exitAppInterface) {
    window.webkit.messageHandlers.exitAppInterface.postMessage("Exit!");
  }
  if (window.WebViewHandler && window.WebViewHandler.closeSdk) {
    window.WebViewHandler.closeSdk();
  }
  if (getOS() !== "android" || getOS() !== "ios") {
    window.location.href = baseVrbt + "onboarding";
  }
  return false;
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Refresh token if expired before making the request
  if (isTokenExpired()) {
    const refreshed = await refreshToken(api, extraOptions);
    if (!refreshed) {
      return {
        error: { status: 401, data: "Token expired and refresh failed" },
      };
    }
  }

  let result = await baseQuery(args, api, extraOptions);

  // Handle token expiration (401) or server errors (500)
  if (result.error) {
    const status = result.error.status;

    if (status === 401 || status === 500) {
      console.warn(
        `Handling error status ${status}: Retrying after token refresh...`
      );

      const refreshed = await refreshToken(api, extraOptions);
      if (refreshed) {
        // Retry the original query with the new token
        result = await baseQuery(args, api, extraOptions);
      } else {
        console.error("Token refresh failed.");
      }
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    // sign in
    signIn: builder.mutation({
      query: ({ email, password }) => ({
        url: `sign-in`,
        method: "POST",
        body: { email, password },
      }),
    }),
    // sign up
    signUp: builder.mutation({
      query: (data) => ({
        url: `sign-up`,
        method: "POST",
        body: data,
      }),
    }),
    //   partner-account-detail
    partnerAccountDetail: builder.mutation({
      query: ({ id }) => ({
        url: `partner-account-detail?id=${id}`,
        method: "GET",
      }),
    }),
    // get bank
    getBank: builder.mutation({
      query: () => ({
        url: `banks`,
        method: "GET",
      }),
    }),
    // get province and city
    getProvince: builder.mutation({
      query: () => ({
        url: `get-province-and-city`,
        method: "GET",
      }),
    }),
    // get artist
    getArtist: builder.mutation({
      query: ({ id }) => ({
        url: `get-artist?id=${id}`,
        method: "GET",
      }),
    }),
    // create artist
    createArtist: builder.mutation({
      query: (data) => ({
        url: `create-artist`,
        method: "POST",
        body: data,
      }),
    }),
    // update artist
    updateArtist: builder.mutation({
      query: (data) => ({
        url: `update-artist`,
        method: "PUT",
        body: data,
      }),
    }),
    // get all partner management
    getAllPartnerManagement: builder.mutation({
      query: ({ id }) => ({
        url: `partner-management?id=${id}`,
        method: "GET",
      }),
    }),
    // partner assesment
    partnerAssesment: builder.mutation({
      query: (data) => ({
        url: `partner-assesment`,
        method: "POST",
        body: data,
      }),
    }),
    // parner deactivate
    partnerDeactivate: builder.mutation({
      query: (data) => ({
        url: `partner-deactivate`,
        method: "POST",
        body: data,
      }),
    }),
    // get all inboxes
    getAllInboxes: builder.mutation({
      query: ({ uid }) => ({
        url: `get-all-messages?uid=${uid}`,
        method: "GET",
      }),
    }),
    // update inboxes
    updateInboxes: builder.mutation({
      query: (data) => ({
        url: "update-messages",
        method: "PUT",
        body: data,
      }),
    }),
    // check email
    checkEmail: builder.mutation({
      query: ({ email }) => ({
        url: `check-email?email=${email}`,
        method: "GET",
      }),
    }),
    // check company name
    checkCompanyName: builder.mutation({
      query: ({ company_name }) => ({
        url: `check-company-name?company_name=${company_name}`,
        method: "GET",
      }),
    }),
    // get categories content
    getCategoriesContent: builder.mutation({
      query: () => ({
        url: `get-categories`,
        method: "GET",
      }),
    }),
    // get content type
    getContentType: builder.mutation({
      query: () => ({
        url: `get-content-type`,
        method: "GET",
      }),
    }),
    // get subs plan
    getSubsPlan: builder.mutation({
      query: () => ({
        url: `get-subs-plan`,
        method: "GET",
      }),
    }),
    //add content classses
    addContentClass: builder.mutation({
      query: (data) => ({
        url: "add-content-classes",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    //get content classes
    getContentClasses: builder.mutation({
      query: ({ id }) => ({
        url: `get-content-classes/`,
        method: "GET",
      }),
    }),
    getContentClass: builder.query({
      query: ({ search, status, sort, page = 1 }) =>
        `get-content-classes?search=${search}&page=${page}&status=${status}&sort=${sort}`,
      providesTags: ["content-class"],
    }),
    // edit content classes
    editContentClasses: builder.mutation({
      query: (data) => ({
        url: `edit-content-classes`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    editNameContentClasses: builder.mutation({
      query: (data) => ({
        url: `edit-name-content-classes`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    editContentClassDetail: builder.mutation({
      query: (data) => ({
        url: `edit-content-class-detail`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    deleteContentClassDetail: builder.mutation({
      query: (data) => ({
        url: "delete-content-class-detail",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    // get content list
    getContentList: builder.mutation({
      query: ({
        id,
        partner_id = "",
        artist_id = "",
        type = "",
        status = "",
      }) => ({
        url: `get-content-list?id=${id}&partner_id=${partner_id}&artist_id=${artist_id}&type=${type}&status=${status}`,
        method: "GET",
      }),
    }),
    getContentClassDetail: builder.query({
      query: ({ page = 1, id }) =>
        `get-content-class-detail/${id}?page=${page}`,
      providesTags: ["content-class"],
    }),
    getContentGroupDetail: builder.mutation({
      query: ({ page = 1, id }) => ({
        url: `get-content-class-detail/${id}?page=${page}`,
        method: "GET",
      }),
    }),
    //add content list detail
    addContentClassDetail: builder.mutation({
      query: (data) => ({
        url: "add-content-class-detail",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    // create content
    createContent: builder.mutation({
      query: (data) => ({
        url: "create-content",
        method: "POST",
        body: data,
      }),
    }),
    // content detail
    contentDetail: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-content-detail?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // get price by content id
    getPriceContent: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-price-content?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // accept content
    acceptContent: builder.mutation({
      query: (data) => ({
        url: `accept-content`,
        method: "POST",
        body: data,
      }),
    }),
    // reject content
    rejectContent: builder.mutation({
      query: (data) => ({
        url: `reject-content`,
        method: "POST",
        body: data,
      }),
    }),
    // update status content
    changeStatusContent: builder.mutation({
      query: (data) => ({
        url: `change-content-status`,
        method: "PUT",
        body: data,
      }),
    }),
    // get content like
    getContentLike: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-content-like?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // get content report
    getContentReport: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-content-report?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // content config charging
    getConfigCharging: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-charging-config?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // update data content
    updateDataContent: builder.mutation({
      query: (data) => ({
        url: `edit-content`,
        method: "PUT",
        body: data,
      }),
    }),
    // update config charging
    updateConfigCharging: builder.mutation({
      query: ({ data, uid }) => ({
        url: `update-config-charging?uid=${uid}`,
        method: "PUT",
        body: data,
      }),
    }),
    // update pic
    updateDataPic: builder.mutation({
      query: (data) => ({
        url: `edit-partner-pic`,
        method: "PUT",
        body: data,
      }),
    }),
    // extend partnership
    extendPartnership: builder.mutation({
      query: (data) => ({
        url: `extend-partnership`,
        method: "PUT",
        body: data,
      }),
    }),
    // REPORT//
    // Revenue Report
    revenueReport: builder.mutation({
      query: (data) => ({
        url: `revenue-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Purchase Report
    purchaseReport: builder.mutation({
      query: (data) => ({
        url: `purchase-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Content Report
    contentReport: builder.mutation({
      query: (data) => ({
        url: `content-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Subscription Report
    subscriptionReport: builder.mutation({
      query: (data) => ({
        url: `subscription-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Like & Report
    likeAndReport: builder.mutation({
      query: (data) => ({
        url: `like-and-report`,
        method: "POST",
        body: data,
      }),
    }),
    // DR Report
    drReport: builder.mutation({
      query: (data) => ({
        url: `dr-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Summary Report
    summaryReport: builder.mutation({
      query: (data) => ({
        url: `summary-report`,
        method: "POST",
        body: data,
      }),
    }),
    dashboardReport: builder.mutation({
      query: (data) => ({
        url: `report-dashboard`,
        method: "POST",
        body: data,
      }),
    }),
    // REPORT//

    // multilang
    multilang: builder.mutation({
      query: ({ lang }) => ({
        url: `get-multilang?lang=${lang}`,
        method: "GET",
      }),
    }),
    // multilang

    // WAP
    // GetToken: builder.mutation({
    //   query: (data) => {
    //     const formData = new FormData();
    //     Object.keys(data).forEach((key) => {
    //       formData.append(key, data[key]);
    //     });

    //     return {
    //       url: "/get_token",
    //       method: "POST",
    //       body: formData,
    //     };
    //   },
    //   invalidatesTags: ["collection"],
    // }),
    GetToken: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        return {
          url: "/get_token",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["collection"],
    }),
    RefreshToken: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        return {
          url: "/refresh_token",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["collection"],
    }),
    WapToken: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        return {
          url: "/wap_token",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["collection"],
    }),
    LoginWAP: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        return {
          url: "/auth/login_wap",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["collection"],
    }),
    GetVnspList: builder.query({
      query: () => "/content/list",
    }),
    GetMarketplace: builder.query({
      query: () => "/content/marketplace",
      providesTags: ["collection"],
      keepUnusedDataFor: 0,
    }),
    GetCategories: builder.query({
      query: () => "/categories/get",
    }),
    SearchContent: builder.mutation({
      query: (data) => ({
        url: "content/search",
        method: "POST",
        body: data,
      }),
    }),
    GetContent: builder.query({
      query: ({ artist, song }) => `?artist=${artist}&song=${song}`,
    }),
    GetContentByClass: builder.query({
      query: ({ content_class_id }) =>
        `/content/get?content_class_id=${content_class_id}`,
    }),
    BuyContent: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        return {
          url: "/content/buy",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["collection"],
    }),
    GetDetailVnsp: builder.query({
      query: ({ content_id }) => `/content/detail?content_id=${content_id}`,
    }),
    GetSubsPlan: builder.query({
      query: ({ content_id }) => `/subs/get?content_id=${content_id}`,
    }),
    GetWAPSearch: builder.query({
      query: ({ song, artist, category }) =>
        `/wap?s=${song}&artist=${artist}&category=${category}`,
    }),
    GetTNC: builder.query({
      query: () => `/auth/wording?keyword=tnc`,
    }),
    GetCollections: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        return {
          url: "/profile/collection",
          method: "POST",
          body: formData,
        };
      },
      providesTags: ["collection"],
      invalidatesTags: ["collection"],
    }),
    GetCollectionDetail: builder.query({
      query: ({ content_id, msgindex }) =>
        `/profile/detail?content_id=${content_id}&msgindex=${msgindex}`,
      providesTags: ["collection"],
    }),
    SetActivationNSP: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        return {
          url: "/profile/set_nsp",
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["collection"],
    }),
  }),
  keepUnusedDataFor: 0, // Immediately discard unused data
  refetchOnMountOrArgChange: true, // Refetch on every component mount
  refetchOnReconnect: true, // Refetch when the network reconnects
  tagTypes: ["content-class", "collection"],
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useGetProvinceMutation,
  usePartnerAccountDetailMutation,
  useGetBankMutation,
  useGetArtistMutation,
  useCreateArtistMutation,
  useUpdateArtistMutation,
  useGetAllPartnerManagementMutation,
  usePartnerAssesmentMutation,
  usePartnerDeactivateMutation,
  useGetAllInboxesMutation,
  useUpdateInboxesMutation,
  useCheckEmailMutation,
  useCheckCompanyNameMutation,
  // content
  useGetCategoriesContentMutation,
  useGetContentListMutation,
  useGetContentTypeMutation,
  useGetSubsPlanMutation,
  useCreateContentMutation,
  useContentDetailMutation,
  useGetPriceContentMutation,
  useAcceptContentMutation,
  useRejectContentMutation,
  useChangeStatusContentMutation,
  useGetContentLikeMutation,
  useGetContentReportMutation,
  useUpdateDataContentMutation,
  useUpdateDataPicMutation,
  // config charging
  useGetConfigChargingMutation,
  useUpdateConfigChargingMutation,
  // partnership
  useExtendPartnershipMutation,
  // content class
  useAddContentClassDetailMutation,
  useGetContentClassDetailQuery,
  useGetContentClassesMutation,
  useGetContentClassQuery,
  useAddContentClassMutation,
  useEditContentClassesMutation,
  useEditNameContentClassesMutation,
  useEditContentClassDetailMutation,
  useDeleteContentClassDetailMutation,
  useGetContentGroupDetailMutation,
  // report
  useRevenueReportMutation,
  useContentReportMutation,
  useDrReportMutation,
  useLikeAndReportMutation,
  useSummaryReportMutation,
  usePurchaseReportMutation,
  useSubscriptionReportMutation,
  useDashboardReportMutation,
  // multilang
  useMultilangMutation,
  // WAP
  useGetContentQuery,
  useGetContentByClassQuery,
  useLazyGetContentQuery,
  useLazyGetVnspListQuery,
  useGetTokenMutation,
  useRefreshTokenMutation,
  useWapTokenMutation,
  useLoginWAPMutation,
  useBuyContentMutation,
  useGetMarketplaceQuery,
  useLazyGetMarketplaceQuery,
  useGetVnspListQuery,
  useGetCategoriesQuery,
  useSearchContentMutation,
  useGetDetailVnspQuery,
  useGetSubsPlanQuery,
  useGetWAPSearchQuery,
  useGetTNCQuery,
  useGetCollectionsMutation,
  useGetCollectionDetailQuery,
  useSetActivationNSPMutation,
} = api;
