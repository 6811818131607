import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FIREBASE_API_BASE_URL } from "./constants";

const api_secret = "1:655740818190:ios:95b715f2b79e2d5b";
const firebase_app_id = "nUdaGXJpTiawqSzkPQsW6Q";

export const firebaseApi = createApi({
  reducerPath: "firebaseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: FIREBASE_API_BASE_URL,
  }),
  endpoints: (builder) => ({
    logEvent: builder.mutation({
      query: ({ payload }) => ({
        url: `?api_secret=${api_secret}&firebase_app_id=${firebase_app_id}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useLogEventMutation } = firebaseApi;
